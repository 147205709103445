import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import "./Box.scss";
import "../../views/Dashboard/Intentions/Intentions.scss"

function Box(props: any) {
    return (
        <div className={`optiwe-box ${props.className}`}>
            <div className="opt-app__recomendation-header">
                <p className="opt-app__title">{props.title}</p>
                <div className="opt-app__recomendation-actions">
                    {!props.hideEdit && (
                        <FontAwesomeIcon icon={faEdit} onClick={props.editCb} />
                    )}
                    {!props.hideDelete && (
                        <FontAwesomeIcon
                            icon={faTrash}
                            className="delete-icon"
                            onClick={props.deleteCb}
                        />
                    )}
                </div>
            </div>
            <div className="opt-app__recomendation-content">
                <p className="opt-app__text">{props.text}</p>
            </div>
            <div className="opt-app__recomendation-id">
                <p className="opt-app__text">ID: {props.id}</p>
            </div>
        </div>
    );
}
export default Box;
