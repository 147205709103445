import React, { useState, useEffect, useMemo, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Intention } from "frontend-lib/DomainTypes/Intentions";
import Box from "components/Box/Box";
import useIntentions from "./useIntentions";
import notFoundImg from "img/question-not-found.png";
import { confirmAlert } from "react-confirm-alert";
import { NavLink, Redirect } from "react-router-dom";
import TitleTooltip from "components/TitleTooltip/TitleTooltip";
import Loader from "components/Loader/Loader";
import { Checkbox, Select } from "antd";
import "./Intentions.scss";
import api from "../../../requests/api";
import { Chatbot } from "frontend-lib/DomainTypes/Chatbot";
import { AppContext } from "../../../components/AppContext";

function Intentions() {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [searchKeywords, setSearchKeywords] = useState<string[]>([]);
    const [baseChatbotId, setBaseChatbotId] = useState<number>();
    const [isSystemIntention, setIsSystemIntention] = useState<boolean | undefined>(undefined);
    const [intentionsList, , , , deleteIntention, , loadIntentions] = useIntentions(setIsLoading);
    const [intentionToEdit, setIntentionToEdit] = useState<Intention | undefined>();
    const [chatbots, setChatbots] = useState<Chatbot[]>([]);
    const appContext = useContext(AppContext);
    //@ts-ignore
    const isSysadmin = useMemo(() => appContext.state?.globalUser?.isSysadmin, [appContext.state?.globalUser?.isSysadmin]);

    const deleteIntentionConfirm = function (intention: Intention) {
        confirmAlert({
            title: t("confirmDelete"),
            message: t("confirmDeleteText"),
            buttons: [
                {
                    label: t("yes"),
                    //@ts-ignore
                    onClick: () => deleteIntention(intention.id),
                },
                {
                    label: t("no"),
                    onClick: () => null,
                },
            ],
        });
    };

    const editIntention = function (intention: Intention) {
        setIntentionToEdit(intention);
    };

    useEffect(() => {
        loadIntentions(baseChatbotId, searchKeywords, isSystemIntention, true)
    }, [searchKeywords, isSystemIntention, baseChatbotId, loadIntentions]);

    useEffect(() => {
        setIsLoading(true);
        api.chatbots()
            .list()
            .then((res) => {
                setChatbots(res.data.chatBots);
                setIsLoading(false);
            });
    }, []);

    if (intentionToEdit) {
        return (
            <Redirect
                to={{
                    pathname: "/dashboard/intentions/form",
                    state: { intentionToEdit: intentionToEdit },
                }}
            />
        );
    }

    return (
        <div className="opt-view opt-view--intention">
            <Loader isLoading={isLoading} />
            <div className="opt-view-header">
                <div className="opt-view-header__title-wrapper">
                    <h1 className="opt-title">
                        {t("intentions.title")}
                        <TitleTooltip content={t("intentions.titleTooltip")} />
                    </h1>
                    <div className="opt-view-filters">
                        <div className="opt-view-filters__select">
                            <label className="opt-label-title">{t("intentions.filter")}</label>

                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <Select
                                    style={{ width: "200px", marginTop: "10px" }}
                                    mode="tags"
                                    placeholder={t("intentions.keywords")}
                                    onChange={(values: string[]) => setSearchKeywords(values)}
                                >
                                    {searchKeywords.map((searchKeyword, index) => {
                                        return (
                                            <Select.Option
                                                key={searchKeyword}
                                                value={searchKeyword}
                                            >
                                                {searchKeyword}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>

                                <Select
                                    style={{ width: "200px", marginLeft: "20px", marginTop: "10px" }}
                                    placeholder={t("intentions.chatbots")}
                                    onChange={(value) => setBaseChatbotId(Number(value))}
                                    options={chatbots.map((chatbot) => {
                                        return { value: chatbot.id, label: chatbot.name };
                                    })}
                                    allowClear
                                />

                                <Checkbox 
                                    onChange={(check) => check.target.checked? setIsSystemIntention(true): setIsSystemIntention(undefined)}
                                    style={{ marginLeft: "20px", marginTop: "10px" }}
                                >
                                    {t("intentions.systemIntentionFilter")}
                                </Checkbox>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="opt-view-header__actions">
                    <button className="opt-button">
                        <NavLink to="/dashboard/intentions/form">
                            {t("intentions.createIntention")}
                        </NavLink>
                    </button>
                </div>
            </div>

            <div className="noIntentions-wrapper">
                {intentionsList.length === 0 && (
                    <div className="opt-view opt-view--not-found">
                        <div className="not-found">
                            <img src={notFoundImg} alt="Not found" />
                            <span className="not-found-text">{t("intentions.noIntentions")}</span>
                        </div>
                    </div>
                )}
            </div>
            <div className="intention-wrapper">
                {intentionsList.map((intention, index) => (
                    <Box
                        className={intention.isSystemIntention ? 'systemIntention-box' : 'customIntention-box'}
                        hideEdit={!isSysadmin}
                        hideDelete={intention.isSystemIntention}
                        key={intention.id}
                        title={intention.isSystemIntention ? `${intention.name} (${t("intentions.isSystemIntention")})` : intention.name}
                        text={intention.description}
                        id={intention.id}
                        deleteCb={() => deleteIntentionConfirm(intention)}
                        editCb={() => editIntention(intention)}/>
                    ))
                }
            </div>
        </div>
    );
}

export default Intentions;
